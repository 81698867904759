import request from '@/utils/request'
//get 请求
/* 获取行政班列表数据 */
export const ding = () => {
  return request({
    method: 'get',
    url: 'ding'
  })
}

/**
 * 请求方式---GET
 * 请求参数---无
 * 学科列表
 */
 export const getSubject = () => {
  return request({
    method: 'get',
    url: 'site/subject',
  })
}